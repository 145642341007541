// assets
import { IconDashboard, IconDeviceAnalytics, IconUser, IconBuildingHospital, IconAmbulance, IconMap2, IconMapPin, IconWorld, IconPackages, IconCar, IconList, IconUsers, IconListDetails, IconHourglass } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser: IconUser,
    IconBuildingHospital: IconBuildingHospital,
    IconAmbulance: IconAmbulance,
    IconMap2: IconMap2,
    IconMapPin: IconMapPin,
    IconWorld: IconWorld,
    IconDeviceAnalytics,
    IconPackages: IconPackages,
    IconCar: IconCar,
    IconList: IconList,
    IconUsers: IconUsers,
    IconListDetails: IconListDetails,
    IconHourglass: IconHourglass,
};


export const dispatcher = {
    id: 'dispatcher',
    title: 'Dispatcher',
    type: 'group',
    children: [
        {
            id: 'dispatcher-list',
            title: 'Dispatcher List',
            type: 'item',
            url: '/dispatcher/list',
            icon: icons['IconPackages'],
            roles: ['admin'],
            breadcrumbs: false
        },
        {
            id: 'dispatch-staff-list',
            title: 'Staff List',
            type: 'item',
            url: '/dispatch-staff/list',
            icon: icons['IconUsers'],
            breadcrumbs: false
        },
        {
            id: 'driver-list',
            title: 'Driver',
            type: 'collapse',
            icon: icons['IconCar'],
            children: [
                {
                    id: 'driver-list',
                    title: 'All Driver',
                    type: 'item',
                    url: '/driver/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: 'booking',
            title: 'Bookings',
            type: 'collapse',
            icon: icons['IconCar'],
            children: [
                {
                    id: 'booking-list',
                    title: 'New Requests',
                    type: 'item',
                    url: '/booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'pending-booking-list',
                    title: 'Pending',
                    type: 'item',
                    url: '/pending-booking/list',
                    icon: icons['IconHourglass'],
                    breadcrumbs: false,
                },
                {
                    id: 'confirm-booking-list',
                    title: 'Confirmed',
                    type: 'item',
                    url: '/confirm-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'ongoing-booking-list',
                    title: 'Ongoing',
                    type: 'item',
                    url: '/ongoing-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'completed-booking-list',
                    title: 'Completed',
                    type: 'item',
                    url: '/completed-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'cancelled-booking-list',
                    title: 'Cancelled',
                    type: 'item',
                    url: '/cancelled-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                }

            ],
        },
        {
            id: 'return',
            title: 'Return Bookings',
            type: 'collapse',
            icon: icons['IconCar'],
            children: [
                {
                    id: 'return-booking-list',
                    title: 'Return Request',
                    type: 'item',
                    url: '/return-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'return-confirm-list',
                    title: 'Confirm Request',
                    type: 'item',
                    url: '/return-confirm-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'return-ongoing-list',
                    title: 'Ongoing Request',
                    type: 'item',
                    url: '/return-ongoing-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },
                {
                    id: 'return-completed-list',
                    title: 'Completed Request',
                    type: 'item',
                    url: '/return-completed-booking/list',
                    icon: icons['IconListDetails'],
                    breadcrumbs: false,
                },

            ],
        },
    ],
}